import React, { useEffect, useState } from "react";
import logo from "./logo.svg";
import "./App.css";
import * as emHost from "@elliemae/em-ssf-guest";

function App() {
  const [application, setApplication] =
    useState<emHost.ApplicationObject | null>(null);
  const [transaction, setTransaction] =
    useState<emHost.TransactionObject | null>(null);
  useEffect(() => {
    (async () => {
      console.log("Fetching application...");
      const application = await emHost.getObject("application");
      console.log("Got application");
      setApplication(application);

      console.log("Fetching transaction...");
      const transaction = await emHost.getObject("transaction");
      console.log("Got transaction");
      setTransaction(transaction);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (!application || !transaction) {
        return;
      }

      console.log("Ready to use host objects");
      console.log("App capabilities:", await application.getCapabilities());
      console.log("App descriptor:", await application.getDescriptor());
      console.log("Transaction:", await transaction.get());
      console.log("Transaction origin:", await transaction.getOrigin());
    })();
  }, [application, transaction]);

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.tsx</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>
  );
}

export default App;
